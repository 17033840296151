<template>
  <v-dialog v-model="dialog" persistent width="800">
    <v-form ref="formData" v-model="valid" onSubmit="return false;">
      <v-card>
        <div class="pa-3">
          <div class="d-flex align-center justify-space-between">
            <div class="text-h6">{{ type === TYPE_CREATE ? 'Create user' : 'Update user' }}</div>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-divider class="mt-2" />
        </div>
        <div class="d-flex pa-3">
          <div class="mr-2" style="width: 330px">
            <div>
              <v-img :src="AvatarBgImg" style="text-align: center;" width="100%">
                <v-avatar class="mt-2" size="200" style="border: 2px solid white">
                  <v-img :src="AvatarImg"></v-img>
                </v-avatar>
              </v-img>
            </div>

            <v-text-field
              v-model="form.email"
              :rules="[rules.feildRequired]"
              autofocus
              class="mt-5 mr-3 ml-3"
              dense
              placeholder="Email"
              prepend-inner-icon="mdi-email-open-outline"
            ></v-text-field>
          </div>
          <div class="ml-2" style="flex: 1">
            <div class="d-flex mt-2">
              <v-text-field
                v-model="form.name"
                :rules="[rules.feildRequired]"
                autofocus
                class="mr-2"
                dense
                label="Name"
                outlined
                placeholder="name"
              ></v-text-field>

              <v-select
                v-model="form.role"
                :items="roles"
                :rules="[rules.feildRequired]"
                autofocus
                class="ml-2"
                dense
                item-text="name"
                item-value="code"
                label="Role"
                outlined
                placeholder="Role"
              ></v-select>
            </div>
            <v-select
              v-model="form.organization_id"
              :items="organizations"
              autofocus
              class="mt-3"
              dense
              item-text="name"
              item-value="id"
              label="Organization"
              outlined
              placeholder="Organization"
            ></v-select>
            <div>Password</div>
            <div class="d-flex mt-2">
              <v-text-field
                v-model="form.password"
                :rules="type === TYPE_CREATE ? [rules.feildRequired] : []"
                autofocus
                class="mr-2"
                dense
                outlined
                placeholder="Password"
                type="password"
              ></v-text-field>

              <v-text-field
                v-model="form.password_confirmation"
                :rules="type === TYPE_CREATE ? [rules.feildRequired] : []"
                autofocus
                class="ml-2"
                dense
                outlined
                placeholder="Password confirmation"
                type="password"
              ></v-text-field>
            </div>
            <div class="mt-1 pl-2" style="font-size: 13px; color: gray; font-style: italic;">
              Password min 8 characters
            </div>
          </div>
        </div>
        <v-card-actions>
          <v-layout>
            <v-spacer />
            <v-btn :disabled="loading" class="mx-2" color="secondary" rounded type="reset" @click="dialog = false">
              Close
            </v-btn>
            <v-btn :loading="loading" color="primary" rounded type="submit" @click="submitData">
              Submit
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import AvatarImg from '@/assets/images/user/avatar.png'
import AvatarBgImg from '@/assets/images/user/background-avatar.png'
import { createUser, updateUser } from '@/api/user'

const TYPE_CREATE = 'create'
const TYPE_UPDATE = 'update'
export default {
  props: {
    roles: { type: Array, default: () => [] },
    organizations: { type: Array, default: () => [] },
  },
  data: () => ({
    TYPE_CREATE,
    TYPE_UPDATE,
    dialog: false,
    loading: false,
    valid: false,
    name: undefined,
    rules: {
      feildRequired: value => !!value || 'Feild is required',
    },
    type: TYPE_CREATE,
    form: {},
    AvatarImg,
    AvatarBgImg,
  }),
  methods: {
    showCreate() {
      this.type = TYPE_CREATE
      this.form = {}
      this.dialog = true
      if (this.$refs.formData) {
        this.$refs.formData.resetValidation()
      }
    },
    showEdit(data) {
      this.type = TYPE_UPDATE
      this.form = { ...data }
      this.dialog = true
    },
    async submitData() {
      try {
        this.$refs.formData.validate()
        if (!this.valid) return
        this.loading = true
        if (this.type === TYPE_CREATE) {
          await createUser(this.form)
        }
        if (this.type === TYPE_UPDATE) {
          await updateUser(this.form.uuid, this.form)
        }
        this.dialog = false
        this.$parent.getListUsers()
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
