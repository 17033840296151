var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"formData",attrs:{"onSubmit":"return false;"},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',[_c('div',{staticClass:"pa-3"},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',{staticClass:"text-h6"},[_vm._v("Update menu user")]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider',{staticClass:"mt-2"})],1),_c('div',{staticClass:"d-flex pa-3"},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.listMenu,"items-per-page":-1,"fixed-header":"","height":"400","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',[_vm._v(_vm._s(+index + 1))])]}},{key:"item.viewable",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"mt-0",attrs:{"color":"red","hide-details":""},model:{value:(item.viewable),callback:function ($$v) {_vm.$set(item, "viewable", $$v)},expression:"item.viewable"}})]}},{key:"item.have_license",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"mt-0",attrs:{"color":"red","hide-details":""},model:{value:(item.have_license),callback:function ($$v) {_vm.$set(item, "have_license", $$v)},expression:"item.have_license"}})]}},{key:"item.default",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"mt-0",attrs:{"color":"red","hide-details":""},model:{value:(item.default),callback:function ($$v) {_vm.$set(item, "default", $$v)},expression:"item.default"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-layout',[_c('v-spacer'),_c('v-btn',{staticClass:"mx-2",attrs:{"disabled":_vm.loading,"color":"secondary","rounded":"","type":"reset"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary","rounded":"","type":"submit"},on:{"click":_vm.submitData}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }