<template>
  <v-dialog v-model="dialog" persistent width="800">
    <v-form ref="formData" v-model="valid" onSubmit="return false;">
      <v-card>
        <div class="pa-3">
          <div class="d-flex align-center justify-space-between">
            <div class="text-h6">Update menu user</div>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-divider class="mt-2" />
        </div>
        <div class="d-flex pa-3">
          <v-data-table
            :headers="headers"
            :items="listMenu"
            :items-per-page="-1"
            class="elevation-1"
            fixed-header
            height="400"
            hide-default-footer
            style="width: 100%"
          >
            <template v-slot:[`item.index`]="{ item, index }">
              <div>{{ +index + 1 }}</div>
            </template>
            <template v-slot:[`item.viewable`]="{ item }">
              <v-checkbox v-model="item.viewable" class="mt-0" color="red" hide-details></v-checkbox>
            </template>
            <template v-slot:[`item.have_license`]="{ item }">
              <v-checkbox v-model="item.have_license" class="mt-0" color="red" hide-details></v-checkbox>
            </template>
            <template v-slot:[`item.default`]="{ item }">
              <v-checkbox v-model="item.default" class="mt-0" color="red" hide-details></v-checkbox>
            </template>
          </v-data-table>
        </div>
        <v-card-actions>
          <v-layout>
            <v-spacer />
            <v-btn :disabled="loading" class="mx-2" color="secondary" rounded type="reset" @click="dialog = false">
              Close
            </v-btn>
            <v-btn :loading="loading" color="primary" rounded type="submit" @click="submitData">
              Submit
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import AvatarImg from '@/assets/images/user/avatar.png'
import AvatarBgImg from '@/assets/images/user/background-avatar.png'
import { getMenuUser, updateMenuUser } from '@/api/user'
import { mapState } from '@/store/ults'

const TYPE_CREATE = 'create'
const TYPE_UPDATE = 'update'
export default {
  props: {
    roles: { type: Array, default: () => [] },
    organizations: { type: Array, default: () => [] },
  },
  computed: {
    ...mapState('menu', ['menu']),
  },
  data: () => ({
    TYPE_CREATE,
    TYPE_UPDATE,
    dialog: false,
    loading: false,
    valid: false,
    name: undefined,
    rules: {
      feildRequired: value => !!value || 'Feild is required',
    },
    headers: [
      {
        text: '#',
        align: 'start',
        sortable: false,
        value: 'index',
      },
      {
        text: 'Menu',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      {
        text: 'View',
        align: 'start',
        sortable: false,
        value: 'viewable',
        width: '200',
      },
      {
        text: 'Licence',
        align: 'start',
        sortable: false,
        value: 'have_license',
        width: '200',
      },
      {
        text: 'Default',
        align: 'start',
        sortable: false,
        value: 'default',
        width: '200',
      },
    ],
    userId: null,
    type: TYPE_CREATE,
    form: {},
    AvatarImg,
    AvatarBgImg,
    listMenu: [],
  }),
  methods: {
    async showEdit(id) {
      this.userId = id
      await this.getMenu()
      this.dialog = true
    },
    async getMenu() {
      let data = await getMenuUser(this.userId)

      this.listMenu = data.data
    },
    async submitData() {
      try {
        const menus = []
        this.listMenu.forEach(item => {
          if ((!item.viewable || !item.have_license) || item.default) {
            menus.push({ id: item.id, viewable: item.viewable, have_license: item.have_license , default: item.default})
          }
        })
        await updateMenuUser(this.userId, {menus: menus})
        this.dialog = false
        this.$parent.getListUsers()
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
