<template>
  <v-layout column style="width: 100%; height: 100%; overflow-y: hidden">
    <Message />
    <v-container style="max-width: 1640px; height: 100%; overflow: hidden; position: relative">
      <v-layout align-center class="fill-height">
        <div style="height: 85vh; width: 100%; background: var(--v-bgContainer-base); border-radius: 12px">
          <v-overlay :value="loading" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <div class="fill-height overflow-y-hidden px-5 d-flex flex-column" column>
            <div
              style="
                flex: none;
                height: 88px;
                width: 100%;
                text-align: center;
                border-bottom: 1px solid rgba(93, 101, 136, 0.5);
              "
            >
              <v-layout align-center class="fill-height px-5">
                <v-btn class="mr-2" dark fab small @click="$router.push('/admin')">
                  <v-icon>icon-arrow_left</v-icon>
                </v-btn>
                <h3 class="text-uppercase">USER MANAGEMENT</h3>
                <v-spacer />
                <div>{{ totalData }} users</div>
              </v-layout>
            </div>
            <div class="pt-5 d-flex flex-column" style="overflow-y: auto; overflow-x: hidden;">
              <div class="mb-5 d-flex">
                <div class="pr-2" style="width: 330px">
                  <v-text-field
                    v-model="keyword"
                    append-icon="mdi-magnify"
                    background-color="#2F324180"
                    dense
                    hide-details
                    label="Search"
                    placeholder="Search"
                    rounded
                    solo
                    @change="filterData"
                  >
                  </v-text-field>
                </div>
                <div class="pr-2" style="width: 330px">
                  <v-select
                    v-model="filter.role"
                    :items="roles"
                    background-color="#2F324180"
                    dense
                    hide-details
                    item-text="name"
                    item-value="code"
                    label="Role"
                    multiple
                    placeholder="Role"
                    rounded
                    solo
                    @change="filterData"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0">
                        <span>{{ item.name }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text text-caption">
                        (+{{ filter.role.length - 1 }} others)
                      </span>
                    </template>
                  </v-select>
                </div>
                <div class="pr-2" style="width: 330px">
                  <v-select
                    v-model="filter.permission"
                    :items="permissions"
                    background-color="#2F324180"
                    dense
                    hide-details
                    item-text="name"
                    item-value="value"
                    label="Permission"
                    multiple
                    placeholder="Permission"
                    rounded
                    solo
                    @change="filterData"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0">
                        <span>{{ item.name }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text text-caption">
                        (+{{ filter.permission.length - 1 }} others)
                      </span>
                    </template>
                  </v-select>
                </div>
                <div class="pr-2" style="width: 330px">
                  <v-select
                    v-model="filter.menu"
                    :items="menus"
                    background-color="#2F324180"
                    dense
                    hide-details
                    item-text="name"
                    item-value="value"
                    label="Menu"
                    multiple
                    placeholder="Menu"
                    rounded
                    solo
                    @change="getListUsers"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0">
                        <span>{{ item.name }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text text-caption">
                        (+{{ filter.menu.length - 1 }} others)
                      </span>
                    </template>
                  </v-select>
                </div>
                <v-spacer />
                <v-btn color="primary" rounded @click="() => $refs.createDialog.showCreate()">
                  <v-icon left>icon-add_user</v-icon>
                  Create user
                </v-btn>
              </div>
              <div class="pb-2 mt-2" style="flex: 1;overflow-y: auto;">
                <v-simple-table>
                  <v-data-table
                    :headers="headers"
                    :items="users"
                    :options.sync="options"
                    :server-items-length="totalData"
                    class="elevation-1"
                    hide-default-footer
                  >
                    <template v-slot:[`item.name`]="{ item }">
                      <div class="d-flex align-center">
                        <v-avatar size="36" style="background: #3f51b5">
                          <img v-if="item.avatar" :src="item.avatar" alt="Avatar" />
                          <span class="white--text text-h6">{{ item.name[0] }}</span>
                        </v-avatar>
                        <div class="ml-3">
                          <div>{{ item.name }}</div>
                          <div style="font-size: 12px">Role: {{ item.role }}</div>
                        </div>
                      </div>
                    </template>
                    <template v-slot:[`item.active`]="{ item }">
                      <div
                        v-if="currentUser.uuid !== item.uuid"
                        class="d-flex align-center justify-center"
                        @click="showConfirmUser(item)"
                      >
                        <v-switch v-model="item.active" color="success" disabled flat></v-switch>
                      </div>
                    </template>
                    <template v-slot:[`item.organization`]="{ item }" >
                      <div v-if="item.organization" @click="goToOrganization(item.organization)" class="organ">
                        <div>{{ item.organization.name }}</div>
                        <div style="font-size: 12px">{{ item.organization.email }}</div>
                      </div>
                    </template>

                    <template v-slot:[`item.action`]="{ item }">
                      <div class="d-flex align-center justify-center">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn color="pink" icon v-bind="attrs" @click="showEdit(item)" v-on="on">
                              <v-icon>icon-edit_square</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit user</span>
                        </v-tooltip>
                        <v-menu min-width="160" offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon large v-bind="attrs" v-on="on">
                              <v-icon style="font-size: 34px; color: white">icon-menu-dots</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item v-for="(config, i) in listUserConfigMenus" :key="i" @click="showEditMenu(item, config)">
                              <v-list-item-icon style="width: 50px">
                                <v-icon>{{ config.icon }}</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>{{ config.name }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                    </template>
                  </v-data-table>
                </v-simple-table>
              </div>
              <div class="mt-2 pb-2 d-flex justify-end">
                <v-pagination
                  v-model="page"
                  :length="totalPage"
                  :total-visible="10"
                  circle
                  @input="handChangePage"
                ></v-pagination>
              </div>
            </div>
          </div>
        </div>
      </v-layout>
    </v-container>

    <CreateEdit ref="createDialog" :organizations="organizations" :roles="roles" />
    <ConfirmDialog ref="confirmDialog" />
    <CreateOrganization ref="organizationDialog" />
    <EditMenu ref="editmenu" />
    <!-- TODO: optimize: one form -->
    <ConfigForm ref="configServiceDialog" title-config="Config Service" :list-all-datas="services" :list-data="userServices" @getData="getUserService" @updateData="updateUserService"/>
    <ConfigForm ref="configPermissionDialog" title-config="Config Permission" :list-all-datas="permissions" :list-data="userPermissions" @getData="getUserPermission" @updateData="updateUserPermission"/>
    <ConfigSetting ref="configSettingDialog" :settings="settings" @update="getListUsers" :type="'user'" />
  </v-layout>
</template>

<script>
import Message from '@/components/Message.vue'
import { activeDeactive, getMenus, getPermissions, getRole, getUsers, getUserPermission, updateUserPermission, getUserService, updateUserService  } from '@/api/user'
import { getSettings } from '@/api/settings'
import { getListOrganization } from '@/api/organization'
import { getServices } from '@/api/services'
import ConvertDate from '@/utils/convertDate'
import DeleteDialog from '@/components/DeleteDialog.vue'
import ThemeSwitcher from '@/layouts/components/ThemeSwitcher.vue'
import AppBarUserMenu from '@/layouts/components/AppBarUserMenu.vue'
import CreateEdit from './CreateEditUser.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import { mapState } from '@/store/ults'
import CreateOrganization from '@/views/pages/account-settings/CreateOrganization.vue'
import EditMenu from './EditMenu.vue'
import ConfigSetting from '@/components/setting-config.vue'
import ConfigForm from './ConfigForm.vue'

export default {
  name: 'User',
  components: {
    AppBarUserMenu,
    ThemeSwitcher,
    DeleteDialog,
    Message,
    CreateEdit,
    ConfirmDialog,
    CreateOrganization,
    EditMenu,
    ConfigSetting,
    ConfigForm
  },
  data() {
    return {
      listUserConfigMenus: [
        { name: 'Config Menu', icon: 'mdi-account-details', action: 'menu' },
        { name: 'Config Setting', icon: 'mdi-cog-outline', action: 'setting' },
        { name: 'Config Service', icon: 'mdi-account-wrench', action: 'service'},
        { name: 'Config Permission', icon: 'mdi-account-cog', action: 'permission' },
      ],
      ConvertDate,
      keyword: undefined,
      loading: false,
      options: {},
      filter: {
        menu: [],
        permission: [],
        role: [],
      },
      menus: [],
      permissions: [],
      settings: {},
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        { text: 'Organization', value: 'organization', sortable: false },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Created at', value: 'created_at', sortable: true },
        {
          text: 'Active',
          value: 'active',
          align: 'center',
          sortable: true,
        },
        { text: 'Action', value: 'action', align: 'center', sortable: false },
      ],
      users: [],
      page: 1,
      perPage: 7,
      totalPage: 1,
      roles: [],
      totalData: 0,
      organizations: [],
      services: [],
      userServices: [],
      userPermissions: [],
    }
  },
  watch: {
    options: {
      handler() {
        this.getListUsers()
      },
      deep: true,
    },
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  mounted() {
    this.getListUsers()
    this.getUserRole()
    this.getOrganization()
    this.getServices()
    this.getMenus()
    this.getPermissions()
    this.getSettings()
  },
  methods: {
    async getUserPermission(userUuid) {
      const res = await getUserPermission(userUuid)
      this.userPermissions = res.data
    },
    async updateUserPermission(params) {
      await updateUserPermission(params.uuid, { permissions: params.datas })
    },
    async getUserService(userUuid) {
      const res = await getUserService(userUuid)
      this.userServices = res.data
    },
    async updateUserService(params) {
      await updateUserService(params.uuid, { services: params.datas })
    },
    filterData() {
      this.page = 1
      this.getListUsers()
    },
    async getServices() {
      let res = await getServices()
      this.services = res.data.map(item => {
        return {
          ...item,
          value: item.id
        }
      })
    },
    async getMenus() {
      let data = await getMenus({ per_page: 'all'})
      this.menus = data.data
    },
    async getPermissions() {
      let data = await getPermissions()
      this.permissions = data.data
    },
    async getSettings() {
      let data = await getSettings()
      this.settings = data.data

    },
    async getListUsers() {
      try {
        this.loading = true
        const res = await getUsers({
          roles: this.filter.role,
          permissions: this.filter.permission,
          menus: this.filter.menu,
          per_page: this.perPage,
          page: this.page,
          search: this.keyword,
          sort: this.options.sortBy[0]
            ? !this.options.sortDesc[0]
              ? this.options.sortBy[0]
              : `-${this.options.sortBy[0]}`
            : undefined,
        })
        this.users = res.data.data
        this.totalPage = res.data.last_page
        this.totalData = res.data.total
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getOrganization() {
      let data = await getListOrganization({ perPage: 'all' })
      this.organizations = data.data.data
    },
    showConfirmUser(item) {
      const title = item.active ? 'Deacitve user' : 'Active user'
      this.$refs.confirmDialog.openDialog({
        title: title,
        content: `Do you want to ${title} <b>${item.name}</b>`,
        action: async () => {
          await activeDeactive(item.uuid)
          await this.getListUsers()
        },
      })
    },
    async getUserRole() {
      let data = await getRole()
      this.roles = data.data
    },
    async handChangePage(page) {
      this.page = page
      this.getListUsers()
    },
    showEdit(data) {
      this.$refs.createDialog.showEdit(data)
    },
    showEditMenu(item, config) {
      switch(config.action) {
        case 'menu':
          this.$refs.editmenu.showEdit(item.uuid)
          break
        case 'permission':
          this.$refs.configPermissionDialog.showCreate(item.uuid)
          break
        case 'setting':
          this.$refs.configSettingDialog.showDialog(item)
          break
        case 'service':
          this.$refs.configServiceDialog.showCreate(item.uuid)
          break
        default:
          break
      }
    },
    goToOrganization(organization) {
  this.$router.push({
    path: '/admin/organizations',
    query: { keyword: organization.name }
  });
}
  },
}
</script>

<style scoped>
.organ {
  cursor: pointer;
}
.organ :hover {
  color: #3f51b5;
}
</style>
